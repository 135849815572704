<template>
  <div id="print">
    <p style="text-align: center; font-weight: 600">开票通知单</p>
    <div style="margin-bottom: 10px">
      <a-row>
        <a-col :span="8" style="display: flex;">
          <div>客户名称：</div>
          <div style="flex: 1;">{{ orderData.customer_name }}</div>
        </a-col>
      </a-row>
    </div>
    <div>
      <table>
        <thead>
          <tr>
            <th>序号</th>
            <th>业务编号</th>
            <th>接单日期</th>
            <th>进出场日期</th>
            <th>海关编号</th>
            <th>箱型箱类</th>
            <th>上下车费</th>
            <th>进出库费</th>
            <th>其他</th>
            <th>合计费用</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in orderList" :key="index">
            <td>{{ item.index }}</td>
            <td>{{ item.record_no }}</td>
            <td>{{ item.receipt_time }}</td>
            <td>{{ item.in_and_out_time }}</td>
            <td>{{ item.customs_no }}</td>
            <td>{{ item.box_itms }}</td>
            <td>{{ item.boarding_and_alighting_fee }}</td>
            <td>{{ item.import_and_export_fee }}</td>
            <td>{{ item.other_fee }}</td>
            <td>{{ item.sum_fee }}</td>
          </tr>
          <tr class="h100">
            <td style="font-weight: bold;">备注</td>
            <td colspan="9" style="font-weight: bold;">{{ orderData.apply_remark }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <p id="footer">
      <span>开票人：{{ userInfo.name }}</span>
      <span>申请人：{{ orderData.applicant_name }}</span>
      <span>部门经理：张丽青</span>
      <span>申请时间：{{ date }}</span>
    </p>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: `InvoiceSheetBill`,
  components: {},
  props: {
    currentData: { type: Object, default: null },
    balanceData: { type: Array, default: null }
  },
  created() {
    this.initData();
  },
  watch: {
    currentData(val, oldVal) {
      this.orderData = val
    },
    balanceData: {
      handler(val, oldVal) {
        this.initData();
      },
      deep: true
    }
  },
  data() {
    return {
      orderData: {},
      orderList: [],
      userInfo: this.$store.getters.userInfo,
      date: moment().year() + '-' + (moment().month() + 1) + '-' + moment().date()
    }
  },
  methods: {
    initData() {
      this.orderData = this.currentData
      this.orderList = this.balanceData
    }
  }
}
</script>

<style scoped>
@page{
  size:  auto;   /* auto is the initial value */
  margin: 3mm;  /* this affects the margin in the printer settings */
}

html{
  background-color: #FFFFFF;
  margin: 0px;  /* this affects the margin on the html before sending to printer */
}

body{
  border: solid 1px blue ;
  width: 80%; margin: 0px auto;
}

@media print {
  html, body {
    height: inherit;
  }
}

.title{
  width: 100%;
}

.gutter-example >>> .ant-row > div {
  background: transparent;
  border: 0;
}

#print { width: 80%; margin: 0 auto; padding-top: 50px }
#print p, #print table { width: 100% }
table { border-collapse: collapse }
.h100 { height:100px }
td, th { padding: 5px; border: 1px solid #ccc;text-align: center; }
td:nth-child(1), td:nth-child(2) { text-align: center }
td:nth-child(5) { text-align: right }
td:nth-child(6) { text-align: right }
#footer span { display: inline-block; width: 20% }
#footer span:last-child { width: 30% }
</style>
